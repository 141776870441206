import React from "react";
import { StyleSheet, Text, Font, View, Image } from "@react-pdf/renderer";

const ProjectedScoreChart = (props) => {
	Font.register({
		family: "Montserrat-Bold",
		src: "fonts/Montserrat-Bold.ttf",
	});

	const mp = (Math.floor(props.mpPercentile * 10) + 0.5) / 10;
	const rt = (Math.floor(props.ritPercentile * 10) + 0.5) / 10;

	const w = parseInt(props.chartWidth, 10);
	const gap = Math.abs(mp * w - rt * w);

	let statusText = "consistent";
	let statusTextWidth = 70;
	let showArrow = false;
	const stradles = ((mp * 100 + rt * 100) / 2) % 20 === 0;

	if (stradles || Math.abs(mp - rt).toFixed(2) > 0.1) {
		if (mp < rt) {
			statusText = "monitor";
			statusTextWidth = 59;
			showArrow = true;
		} else {
			statusText = "opportunity";
			statusTextWidth = 78;
			showArrow = true;
		}
	}

	//	let statusPosition = x1 + gap / 2 - statusTextWidth / 2;
	let statusPosition =
		mp > 0.5
			? mp * props.chartWidth - statusTextWidth + 7
			: mp * props.chartWidth - 7;

	const styles = StyleSheet.create({
		container: {
			height: 72,
			width: props.chartWidth,
			//	backgroundColor: "beige",
		},
		caretContainer: {
			height: 26,
			width: w + 14,
			display: "flex",
			flexDirection: "row",
			//	backgroundColor: "gray",
		},
		caret: {
			opacity: 0.6,
			width: 14,
			marginTop: -5,
		},
		barContainer: {
			height: 10,
			width: w,
			display: "flex",
			flexDirection: "row",
		},
		bar: {
			flex: 1,
		},
		arrowContainer: {
			marginTop: 0,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",

			//	transform: "rotate(-45deg)",
		},
		arrow: {
			marginTop: 6,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			//	width: gap - 1,
		},
		arrowTipContainer: {
			marginRight: 0,
			marginLeft: -1,
		},
		arrowTipContainerLeft: {
			marginLeft: -1,
			transform: "rotate(180deg)",
		},
		arrowTipTop: {
			backgroundColor: "#DA6667",
			height: 1,
			width: 5,
			marginLeft: -4,
			marginTop: 0,
			transform: "rotate(45deg)",
			opacity: showArrow ? 1.0 : 0,
		},
		arrowTipBottom: {
			backgroundColor: "#DA6667",
			height: 1,
			width: 5,
			marginLeft: -4,
			marginTop: 2,
			opacity: showArrow ? 1.0 : 0,
			transform: "rotate(-45deg)",
		},
		arrowLine: {
			backgroundColor: "#DA6667",
			height: 1,
			width: gap - 1,
			//	marginLeft: 3,
		},
		verticalLine: {
			height: 18,
			width: 1.0,
			backgroundColor: "black",
			opacity: 0.25,
		},
		statusContainer: {
			width: statusTextWidth,
			fontSize: 9,
			marginTop: 5,
			color: "#1376BF",
			fontFamily: "Montserrat-Bold",
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			//	justifyContent: "center",
			marginLeft: statusPosition,
		},
	});

	return (
		<View style={styles.container}>
			<View style={styles.caretContainer}>
				<View
					style={[
						{
							transform: "translateX(" + Math.round(rt * w - 7) + ")",
						},
					]}
				>
					<Image src="/assets/map.png" style={{ height: 12, marginLeft: -6 }} />
					<Image src="/assets/caret-down.png" style={[styles.caret]} />
				</View>
			</View>
			<View style={styles.barContainer}>
				<View style={[styles.bar, { backgroundColor: "#81171a" }]} />
				<View style={[styles.bar, { backgroundColor: "#f78e23" }]} />
				<View style={[styles.bar, { backgroundColor: "#ffc62e" }]} />
				<View style={[styles.bar, { backgroundColor: "#008753" }]} />
				<View style={[styles.bar, { backgroundColor: "#2876b6" }]} />
			</View>
			<View
				style={[
					styles.arrowContainer,
					{
						marginLeft: Math.round((mp < rt ? mp : rt) * props.chartWidth),
					},
				]}
			>
				<View style={styles.verticalLine} />
				{mp < rt ? (
					<View style={styles.arrow}>
						<View style={styles.arrowTipContainerLeft}>
							<View style={styles.arrowTipTop} />
							<View style={styles.arrowTipBottom} />
						</View>
						<View style={styles.arrowLine} />
					</View>
				) : (
					<View style={styles.arrow}>
						<View style={styles.arrowLine} />
						<View style={styles.arrowTipContainer}>
							<View style={styles.arrowTipTop} />
							<View style={styles.arrowTipBottom} />
						</View>
					</View>
				)}
				<View style={[styles.verticalLine, { marginLeft: -1 }]} />
			</View>
			{mp > 0.5 ? (
				<View style={styles.statusContainer}>
					<Text>{statusText}</Text>
					<Image
						src="/assets/mp-brain.png"
						style={{ height: 15, marginLeft: 6 }}
					/>
				</View>
			) : (
				<View style={styles.statusContainer}>
					<Image
						src="/assets/mp-brain.png"
						style={{ height: 15, marginRight: 6 }}
					/>
					<Text>{statusText}</Text>
				</View>
			)}
		</View>
	);
};

export default ProjectedScoreChart;

/*

<View
	style={[
		{
			height: 40,
			transform:
				"translateX(" + Math.round(mp * props.chartWidth - 7) + ")",
		},
	]}
>
	<Image
		src="assets/mp-brain.png"
		style={{ height: 15, marginLeft: -3 }}
	/>
	<Image src="assets/caret-down.png" style={[styles.caret]} />
</View>

*/
