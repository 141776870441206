const api = {};
const API_ROOT = process.env.REACT_APP_PREDICT_URL;
const MP_API_ROOT = process.env.REACT_APP_MP_API_ROOT;
const MP_API_AUTH = process.env.REACT_APP_MP_API_AUTH;

api.ping = () => {
	return get("/ping");
};

api.getPredictions = (data) => {
	console.log("getPredictions data = ", data);
	return post("/predict/", data);
};

api.getStudentData = (id) => {
	return getMpApi(id);
};

// Helper methods

const get = (path) => {
	const config: RequestInit = {
		method: "get",
	};
	return fetch(API_ROOT + path, config)
		.then((res) => {
			if (res.ok) {
				return res.json();
			} else {
				throw Error(`Request rejected with status ${res.status}`);
			}
		})
		.then((result) => {
			return result;
		});
};

const getMpApi = (id) => {
	return fetch(MP_API_ROOT + id, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"X-Authorization": MP_API_AUTH,
		},
	})
		.then((res) => {
			return res.json();
		})
		.then((result) => {
			return result;
		});
};

const post = (path, data = null) => {
	return fetch(API_ROOT + path, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(data),
	})
		.then((res) => {
			return res.json();
		})
		.then((result) => {
			return result;
		});
};

module.exports = api;
