import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const StudentInfoHeader = (props) => {
  const styles = StyleSheet.create({
    studentInfoContainer: {
      fontSize: 12,
      marginTop: 15,
      //	marginBottom: 15,
      background: "beige",
    },
  });
  return (
    <View style={styles.studentInfoContainer}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <View>
          <Text
            style={{
              fontFamily: "Montserrat-Bold",
              fontSize: 11,
              color: "#414042",
            }}
          >
            STUDENT:{" "}
          </Text>
        </View>
        <View>
          <Text
            style={{
              fontFamily: "Montserrat-Regular",
              fontSize: 11,
              color: "#414042",
            }}
          >
            {props.firstName} {props.lastName}{" "}
          </Text>
        </View>
        {props.age ? (
          <View>
            <Text
              style={{
                fontFamily: "Montserrat-Italic",
                opacity: 0.5,
                fontSize: 11,
              }}
            >
              (Age {props.age})
            </Text>
          </View>
        ) : (
          <View>
            <Text
              style={{
                fontFamily: "Montserrat-Italic",
                opacity: 0.5,
                fontSize: 11,
              }}
            >
              (Grade {props.grade})
            </Text>
          </View>
        )}
      </View>

      <Text
        style={{
          fontFamily: "Montserrat-Italic",
          color: "#414042",
          fontSize: 10,
        }}
      >
        {props.school ? props.school : "school"}
      </Text>
    </View>
  );
};

export default StudentInfoHeader;
