import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  Image,
} from "@react-pdf/renderer";
import * as dayjs from "dayjs";

import {
  FAKE_NWEA_DATA,
  getGradeCategory,
  getPercentileCategory,
} from "../helpers";
import { getPredictions } from "../api";

import Loader from "../components/Loader";
import Header from "../components/Header";
import BarChart from "../components/BarChart";
import ProjectedScoreChart from "../components/ProjectedScoreChart";
import StudentInfoHeader from "../components/StudentInfoHeader";
import LearningPlanLogo from "../components/LearningPlanLogo";

class NWEA extends React.Component {
  state = { data: null, ready: false };
  componentDidMount() {
    // Listen for data from parent
    window.addEventListener("message", this.receiveMessage, false);

    console.log("NWEA Mount");
    console.log("dev = ", this.props.dev);
    // simulate loading data from api
    if (this.props.dev) {
      setTimeout(() => {
        this.receiveData(FAKE_NWEA_DATA);
      }, 1000);
    }
  }

  receiveMessage = (event) => {
    if (event.data !== "") {
      if (event.data.eventName === "sendScores") {
        this.receiveData(event.data);
      }
    }
  };

  receiveData = (data) => {
    console.log("RECEIVE STUDENT DATA = ", data);
    if (!data) {
      this.setState({ showNoData: true, ready: true });
      return;
    }

    // show attention?
    const ATT_Az = parseFloat(data.value.scores.ATT_Az, 10);
    const ATT_z_eff = parseFloat(data.value.scores.ATT_z_eff, 10);

    // TODO: make sure this is consistent with MP profile (e.g., is it -0.46?) (note from Nancy)
    const attInvalid =
      data.value.validity.filter((item) => item.skillRoot === "ATT")
        .validity === 0;
    let invalidSkillsList = [...data.value.validity];
    if (ATT_Az < -0.5 || ATT_z_eff < -0.5 || attInvalid) {
      this.setState({ showAttention: true });
    }

    // Show invalid footer and mark invalid scores as -1
    let scores = data.value.scores;
    for (let i = 0; i < Object.entries(scores).length; i++) {
      const prop = Object.entries(scores)[i][0];
      for (let n = 0; n < invalidSkillsList.length; n++) {
        const skillRoot = prop.indexOf(invalidSkillsList[n].skillRoot);
        const skillRootFound = skillRoot !== -1 && skillRoot !== "-1";

        const invalidSkill = invalidSkillsList[n].validity;
        const invalid = invalidSkill === 0 || invalidSkill === "0";

        if (skillRootFound && invalid) {
          scores[prop] = -1;
        }
      }
    }

    // Make sure attention is marked if it's invalid
    for (let i = 0; i < data.value.skills.length; i++) {
      const skill = data.value.skills[i];
      if (skill.validity === 0) {
        this.setState({ showInvalid: true });
      }
    }

    getPredictions({
      grade: getGradeCategory(data.value.grade),
      subject: data.value.subject,
      models: data.value.subscores.map((item) => {
        return item.subCategoryKey;
      }),
      scores: scores,
    }).then((result) => {
      console.log(result);
      this.setState({
        ready: true,
        mpPercentile: result.mpPercent,
        subscoreResults: result.subScores,
        data: data.value,
      });
      window.parent.postMessage(
        { eventName: "sendPredictions", value: result.mpPercent },
        "*"
      );
    });
  };
  render() {
    const data = this.state.data;

    let barChartHeight = 130;
    if (this.state.showAttention) {
      barChartHeight -= 40;
    }
    if (this.state.showInvalid) {
      barChartHeight -= 40;
    }
    // if (this.state.showNoData) {
    //   report = (
    //     <div className="reportUnavailable">
    //       <div>
    //         <h1>Report Unavailable</h1>
    //         <div>
    //           Student has not completed the necessary MAP Growth assessment.
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    if (!this.state.ready) {
      return (
        <div
          key="loader"
          style={{
            transition: "all 0.5s ease",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      );
    } else {
      if (this.state.showNoData) {
        return (
          <div className="reportUnavailable">
            <div>
              <h1>Report Unavailable</h1>
              <div>We are undergoing maintenance. Please check back later.</div>
            </div>
          </div>
        );
      } else {
        return (
          <PDFViewer
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Document>
              <Page size="A4" style={styles.page}>
                <Header
                  firstName={data.firstName}
                  lastName={data.lastName}
                  subject={data.subject}
                  grade={data.grade}
                  school={data.school}
                  width={FULL}
                />
                <View
                  style={{
                    // backgroundColor: "beige",
                    //   textAlign: "left",
                    width: FULL,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Text style={[styles.h1, { marginTop: 20 }]}>Overall</Text>
                  <Text
                    style={[
                      {
                        marginTop: 20,
                        fontSize: 9,
                        fontFamily: "Montserrat-Bold",
                        color: "#231f20",
                      },
                    ]}
                  >
                    MAP Test Date:{" "}
                    <Text
                      style={{
                        fontFamily: "Montserrat-Regular",
                        color: "#231f20",
                      }}
                    >
                      {data.testDate}
                    </Text>
                  </Text>
                </View>
                <View style={styles.topPanel}>
                  <View style={styles.overallPanel}>
                    <Text style={[styles.h2, { fontSize: 9 }]}>
                      Actual vs. Projected Performance
                    </Text>
                    <View>
                      <View style={styles.scoreContainer}>
                        <Text style={styles.scoreLabel}>MAP RIT Score:</Text>

                        <Text
                          style={[
                            styles.pill,
                            {
                              backgroundColor: getPercentileCategory(
                                data.ritPercentile
                              ).color,
                            },
                          ]}
                        >
                          {data.ritScore}
                        </Text>
                      </View>
                      <View style={styles.scoreContainer}>
                        <Text style={styles.scoreLabel}>
                          MP Projected Score:
                        </Text>
                        <Text
                          style={[
                            styles.pill,
                            {
                              backgroundColor: getPercentileCategory(
                                this.state.mpPercentile
                              ).color,
                            },
                          ]}
                        >
                          {getPercentileCategory(this.state.mpPercentile).text}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: FULL - 230,
                    }}
                  >
                    <ProjectedScoreChart
                      mpPercentile={this.state.mpPercentile}
                      ritPercentile={data.ritPercentile}
                      ritScore={data.ritScore}
                      chartWidth="200"
                    />
                  </View>
                </View>
                <View
                  style={{
                    width: FULL,
                    marginTop: 30,
                    marginLeft: 20,
                    marginRight: 20,
                    textAlign: "left",
                  }}
                >
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={[styles.h2, { marginBottom: 10 }]}>
                      What skills drive your Mindprint Projected {data.subject}{" "}
                      Score?
                    </Text>
                    <Text style={[styles.h2, { fontSize: 7 }]}>
                      {this.state.showAttention ? " †" : ""}
                    </Text>
                  </View>
                  <Text style={styles.text}>
                    The Mindprint Projected Score is a weighted calculation that
                    identifies the student’s expected performance based on the
                    Mindprint assessment of cognitive skills. Weightings were
                    developed through statistical correlation across cognitive
                    and academic skills for that grade level. Weightings are
                    shown in order of significance for the top four cognitive
                    skills for {data.subject}.
                  </Text>
                </View>
                <BarChart
                  skills={data.skills.sort((a, b) =>
                    a.order > b.order ? 1 : -1
                  )}
                  mpPercentile={this.state.mpPercentile}
                  height={barChartHeight}
                  firstName={data.firstName}
                  subject={data.subject}
                  grade={data.grade}
                />

                <View
                  style={{
                    width: FULL,
                    marginTop: -18,
                    marginBottom: 10,
                    marginLeft: 12,
                  }}
                >
                  <Link
                    style={[
                      styles.link,
                      {
                        textAlign: "right",
                        marginRight: 20,
                        color: "#f37576",
                        fontFamily: "Montserrat-Bold",
                        fontSize: 10,
                      },
                    ]}
                    src={
                      process.env.REACT_APP_MY_MP_URL +
                      "learning-profile/" +
                      data.customer_id
                    }
                  >
                    View Mindprint profile
                  </Link>

                  <Text
                    style={{
                      textAlign: "right",
                      marginRight: 20,
                      opacity: 0.65,
                      fontFamily: "Montserrat-Italic",
                      fontSize: 9,
                      marginTop: 3,
                    }}
                  >
                    {data.mpTestDate
                      ? " (" +
                        dayjs(data.mpTestDate.date).format("MM/DD/YYYY") +
                        ")"
                      : ""}
                  </Text>
                </View>
                {this.state.showInvalid ? (
                  <View>
                    <Text
                      style={[
                        styles.text,
                        {
                          fontSize: 8,
                          width: FULL / 1.5,
                          fontFamily: "Montserrat-Italic",
                        },
                      ]}
                    >
                      * Invalid scores: {data.firstName} had an invalid score.
                      Invalid scores are treated as if the student has
                      difficulty with the skill. You can read more about invalid
                      skills in {data.firstName}
                      's Mindprint Profile to determine if {data.firstName}'s
                      performance might not accurately reflect{" "}
                      {data.gender === "male" ? "his" : "her"} capabilities.
                    </Text>
                  </View>
                ) : (
                  <View />
                )}

                <View
                  style={{
                    width: FULL,
                    marginTop: 15,
                    marginLeft: 20,
                    marginRight: 20,
                    textAlign: "left",
                  }}
                >
                  <Text style={styles.text}>
                    The Mindprint Projected Score is a personalized benchmark
                    designed to identify opportunities to improve student
                    achievement through learning strategies that leverage a
                    student’s cognitive strengths and support cognitive needs
                    that might interfere with academic outcomes.
                  </Text>
                </View>

                {this.state.showAttention ? (
                  <View style={styles.footnote}>
                    <View
                      style={{
                        marginLeft: 55,
                        marginRight: 55,
                        marginTop: 10,
                        marginBottom: 20,
                      }}
                    >
                      {this.state.showAttention ? (
                        <View
                          style={{ marginTop: this.state.showInvalid ? 10 : 0 }}
                        >
                          <View
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Text style={[styles.h2, { fontSize: 6 }]}>†</Text>
                            <Text style={[styles.h2, { fontSize: 10 }]}>
                              {" "}
                              Attention
                            </Text>
                          </View>
                          <Text style={[styles.text, { fontSize: 9 }]}>
                            Students with weaker{" "}
                            <Link
                              style={styles.link}
                              src={
                                "https://my.mindprintlearning.com/product/11074"
                              }
                            >
                              Attention
                            </Link>{" "}
                            like {data.firstName} can be prone to variability in
                            performance. You might see larger discrepancies
                            between Mindprint projected performance and actual
                            RIT scores for some subjects or greater than
                            expected variance in RIT growth. These strategies
                            can help {data.firstName} during MAP testing so
                            performance reflects full capabilities:
                          </Text>
                          <Text
                            style={[styles.text, { fontSize: 9, marginTop: 5 }]}
                          >
                            1{" "}
                            <Link
                              style={[styles.link, { fontSize: 9 }]}
                              src={
                                process.env.REACT_APP_MY_MP_URL +
                                "product/12838"
                              }
                            >
                              Online Test Taking Stratiges
                            </Link>
                          </Text>
                          <Text
                            style={[styles.text, { fontSize: 9, marginTop: 5 }]}
                          >
                            2{" "}
                            <Link
                              style={[styles.link, { fontSize: 9 }]}
                              src={
                                process.env.REACT_APP_MY_MP_URL +
                                "product/12393"
                              }
                            >
                              Sustain Focus on Standardized Tests
                            </Link>
                          </Text>
                        </View>
                      ) : (
                        <View />
                      )}
                    </View>
                  </View>
                ) : (
                  <View />
                )}
              </Page>

              <Page size="A4" style={[styles.page, { marginTop: 25 }]}>
                <View
                  style={{
                    textAlign: "left",
                    width: FULL,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <StudentInfoHeader
                    firstName={data.firstName}
                    lastName={data.lastName}
                    grade={data.grade}
                    school={data.school}
                  />
                  <LearningPlanLogo
                    subject={data.subject}
                    align="left"
                    size="small"
                  />
                </View>
                <View
                  style={{
                    width: FULL,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    //  backgroundColor: "beige",
                  }}
                >
                  {data.subscores.map((item, index) => {
                    const subscoreValue = this.state.subscoreResults.filter(
                      (obj) => {
                        return obj.key === item.subCategoryKey;
                      }
                    )[0].score;

                    const pad = 30;
                    return (
                      <View
                        key={index}
                        style={{
                          marginBottom: 10,
                          //   height: 300,
                          //   backgroundColor: "red",
                          width: FULL / 2 - pad / 2,
                          marginRight: index % 2 === 0 ? pad : 0,
                          marginTop: 15,
                          //  overflow: "hidden",
                          //  backgroundColor: "gray",
                          //   backgroundColor: index % 2 === 0 ? "#ff0000" : "#00ff00",
                        }}
                      >
                        <Text
                          style={[
                            styles.h1,
                            {
                              textAlign: "left",
                              fontSize: 11,
                              width: "100%",
                              marginBottom: 10,
                              fontFamily: "Montserrat-BoldItalic",
                              // padding: 20,
                            },
                          ]}
                        >
                          {item.name}
                        </Text>
                        <View style={styles.subscorePanel}>
                          <View
                            style={{
                              backgroundColor: "#dfe6ea",
                              // backgroundColor: "blue",
                              //  padding: 20,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              height: 90,
                            }}
                          >
                            <View
                              style={{
                                // backgroundColor: "#f7f6f7",
                                marginLeft: 0,
                                borderRadius: 10,
                                height: 70,
                                width: 80,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Text
                                style={[styles.scoreLabel, { marginBottom: 5 }]}
                              >
                                RIT Score:
                              </Text>
                              <Text
                                style={[
                                  styles.pill,
                                  {
                                    backgroundColor: getPercentileCategory(
                                      item.ritPercentile
                                    ).color,
                                  },
                                ]}
                              >
                                {item.ritScore}
                              </Text>
                            </View>
                            <View
                              style={{
                                // backgroundColor: "beige",
                                width: 140,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ProjectedScoreChart
                                mpPercentile={subscoreValue}
                                ritPercentile={item.ritPercentile}
                                ritScore={item.ritScore}
                                chartWidth="90"
                              />
                            </View>
                          </View>
                          <View
                            style={{
                              backgroundColor: "#F1F5F8",
                              //    backgroundColor: "green",
                              height: 150,
                              display: "flex",
                              // justifyContent: "center",
                              // paddingTop: 20,
                              // paddingBottom: 20,
                              paddingLeft: 20,
                              paddingRight: 20,
                            }}
                          >
                            <Text style={[styles.h2, { marginTop: 15 }]}>
                              Teaching Strategies
                            </Text>
                            <View
                              style={{
                                height: 75,
                                //  backgroundColor: "beige",
                                display: "flex",
                                //  justifyContent: "center",
                              }}
                            >
                              {item.teachingStrategies.map((item, index) => {
                                return (
                                  <Link
                                    key={index}
                                    style={[
                                      styles.link,
                                      { paddingTop: 5, paddingBottom: 5 },
                                    ]}
                                    src={item.url}
                                  >
                                    {item.text}
                                  </Link>
                                );
                              })}
                            </View>
                            <View
                              style={{
                                backgroundColor: "#828386",
                                height: 20,
                                width: 110,
                                borderRadius: 10,
                                marginTop: 5,
                              }}
                            >
                              <Link
                                style={{
                                  fontSize: 9,
                                  color: "#F6F6F6",
                                  marginTop: 4,
                                  textAlign: "center",
                                  fontFamily: "Montserrat-Italic",
                                  textDecoration: "none",
                                }}
                                src={item.moreStrategies.url}
                              >
                                more strategies (10)
                              </Link>
                            </View>
                          </View>
                          <View
                            style={{
                              // paddingTop: 10,
                              // paddingBottom: 10,
                              paddingLeft: 20,
                              paddingRight: 20,
                              height: 70,
                              backgroundColor: "#dfe6ea",
                            }}
                          >
                            <Text style={[styles.h2, { marginTop: 15 }]}>
                              Student Checklist
                            </Text>
                            <Text
                              style={[styles.link, { marginBottom: 5 }]}
                              src={item.checklist.url}
                            >
                              {item.checklist.text}
                            </Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: FULL,
                    marginTop: 10,
                  }}
                >
                  <Image src="/assets/mp-logo.png" style={{ height: 18 }} />
                  <Image
                    src="/assets/mapGrowth-logo.png"
                    style={{ height: 18 }}
                  />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        );
      }
    }
  }
}

export default NWEA;
// render() {
// const props = this.props;
// // const data = this.props.data;

// let barChartHeight = 130;
// if (props.showAttention) {
//   barChartHeight -= 40;
// }
// if (props.showInvalid) {
//   barChartHeight -= 40;
// }

// const data = this.state.data;
// if (!this.state.ready) {
//   return <div>loading</div>;
// }
// if (this.state.ready)

//}
//}

const FULL = 500;

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  page: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    marginTop: 50,
  },
  h1: {
    color: "#7AAD5C",
    fontFamily: "Montserrat-SemiBold-Italic",
    fontSize: 16,
    // width: FULL,
    marginBottom: 5,
  },
  h2: {
    color: "#008dd0",
    fontFamily: "Montserrat-Bold",
    fontSize: 11,
    marginBottom: 5,
    textAlign: "left",
  },
  text: {
    fontFamily: "Montserrat-Regular",
    fontSize: 10,
    opacity: 0.65,
  },
  sup: {
    fontSize: 5,
    lineHeight: 5,
    verticalAlign: "top",
    // marginTop: -5,
  },
  topPanel: {
    width: FULL,
    height: 110,
    backgroundColor: "#f7f6f7",
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  overallPanel: {
    width: 210,
    textAlign: "center",
    padding: 20,
    height: "100%",
    backgroundColor: "#dfe6ea",
  },
  subscorePanel: {
    backgroundColor: "#D9E6EB",
    //   height: 270,
    width: 240,
    borderRadius: 10,
    overflow: "hidden",
  },
  label: {
    fontFamily: "Montserrat-Regular",
    fontSize: "11pt",
  },
  scoreContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    marginTop: 10,
  },
  scoreLabel: {
    fontFamily: "Montserrat-Italic",
    fontSize: 9,
    textAlign: "left",
    paddingTop: 3,
  },
  logo: {
    width: 120,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  subscoreContainer: {
    display: "flex",
    flexDirection: "row",
  },
  panel: {
    backgroundColor: "#D9E6EB",
    borderRadius: 10,
    marginRight: 10,
  },
  pill: {
    color: "white",
    backgroundColor: "#FEB634",
    width: 50,
    padding: 3,
    textAlign: "center",
    fontSize: 9,

    borderRadius: 50,
  },
  score: {
    width: 90,
    fontSize: "14pt",
    fontFamily: "Montserrat-Bold",
  },
  footnote: {
    width: "100%",
    //height: 170,
    backgroundColor: "#F1F5F8",
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    marginBottom: 50,
  },
  link: {
    fontFamily: "Montserrat-Regular",
    textDecorationColor: "#979A9C",
    color: "#000",
    opacity: 0.65,
    fontSize: 10,
  },
});
