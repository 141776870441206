import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";

import Link from "../../components/Link";
import StudentInfoHeader from "../../components/StudentInfoHeader";

const MainPage = ({ data, styles }) => {
  console.log("styles=", styles);
  const FULL = 520;
  const orderedSkills = data.skills.sort((a, b) => {
    return a.order - b.order;
  });
  return (
    <Page size="A4" style={styles.page}>
      <View style={{ width: FULL }}>
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 0,
          }}
        >
          <Text style={styles.h1}>STUDENT LEARNING PROFILE</Text>
          <Image src="/assets/mp-logo-dark.png" style={{ height: 24 }} />
        </View>
        <StudentInfoHeader
          firstName={data.student.firstName}
          lastName={data.student.lastName}
          grade={data.student.grade}
          age={data.student.age}
          school={data.student.school}
        />
        <Text style={[styles.h2, { marginTop: 15 }]}>ABOUT MINDPRINT</Text>
        <Text style={[styles.text, { paddingBottom: 10 }]}>
          Everyone learns differently.{" "}
          <Text style={styles.boldText}>MindPrint skills</Text> (or cognitive
          skills) are the skills your brain uses to help you understand, solve
          problems, remember, plan and pay attention. These skills work together
          to determine <Text style={styles.boldText}>HOW you learn best</Text>{" "}
          in all subjects of school, whether you’re writing, creating a video,
          or taking a math test.
        </Text>
        <Text style={[styles.text, { paddingBottom: 10 }]}>
          No one is equally strong in every skill. Understanding your{" "}
          <Text style={styles.boldText}>stronger skills</Text> will help you
          work more efficiently so you can spend less time studying, and more
          time on things you enjoy. Using the strategies recommended below will
          help you use your strengths most efficiently.
        </Text>
      </View>
      <View
        style={{
          backgroundColor: "#008dd0",
          width: FULL,
          height: 425,
          borderRadius: 10,
          borderWidth: 4,
          borderColor: "#dfe6ea",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            padding: 15,
            //  height: 120,
          }}
        >
          <Image
            src="/assets/lightbulb.png"
            style={{ width: 55, marginRight: 10 }}
          />
          <View style={{ width: 410 }}>
            <Text
              style={{
                fontFamily: "Montserrat-Bold",
                fontSize: 14,
                color: "#fff",
                marginBottom: 5,
              }}
            >
              MY MINDPRINT PROFILE
            </Text>
            <Text style={[styles.text, { color: "#fff", marginBottom: 10 }]}>
              Below is your MindPrint Profile. It lists your skills in each
              category in order of strength.{" "}
              <Text style={{ fontFamily: "Montserrat-Bold" }}>
                Your strongest skill is listed first in each category. You also
                have a strongest overall category. It’s the highlighted tall box
                below
              </Text>
              . When you are faced with a challenge you will always want to
              start with your strengths. The strategies below will help you use
              your strengths.
            </Text>
            <Text style={[styles.text, { color: "#fff" }]}>
              The last skill in each category could be a “blocker” and make
              learning challenging at times, but you can always find ways to use
              your strengths to succeed. Since Executive Functions can be the
              biggest blocker, in that category we give a strategy to help
              minimize any challenge.
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {orderedSkills.map((item, index) => {
            return (
              <Card key={index} skill={item} index={index} styles={styles} />
            );
          })}
        </View>
      </View>

      {(data.mathStrategies || data.languageArtsStrategies) && (
        <View
          style={{
            marginTop: 15,
            textAlign: "left",
            width: FULL,
          }}
        >
          <Text
            style={[
              styles.text,
              {
                color: "#414042",
                fontFamily: "Montserrat-SemiBold-Italic",
              },
            ]}
          >
            Subject Checklists:{" "}
            <Text style={{ fontFamily: "Montserrat-Italic" }}>
              Use these checklists to help you do your best work.
            </Text>
          </Text>
        </View>
      )}

      <View
        style={{
          // backgroundColor: "beige",
          width: FULL,
          height: 100,
          paddingTop: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {data.languageArtsStrategies && (
          <View style={{ width: FULL / 2, height: 100 }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={"/assets/icons/books-duotone.png"}
                style={{ height: 13, marginRight: 10 }}
              />
              <Text
                style={{
                  color: "#636f73",
                  fontSize: 11,
                  marginBottom: 5,
                  fontFamily: "Montserrat-Bold",
                }}
              >
                READING & WRITING
              </Text>
            </View>
            <View style={{ paddingLeft: 25 }}>
              {data.languageArtsStrategies.map((item, index) => {
                return (
                  <Link key={index} src={item.url}>
                    {item.title}
                  </Link>
                );
              })}
            </View>
          </View>
        )}

        {data.mathStrategies && (
          <View style={{ width: FULL / 2, height: 100 }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={"/assets/icons/function-duotone.png"}
                style={{ height: 13, marginRight: 10 }}
              />
              <Text
                style={{
                  color: "#636f73",
                  fontSize: 11,
                  fontFamily: "Montserrat-Bold",
                  marginBottom: 5,
                }}
              >
                MATH & SCIENCE
              </Text>
            </View>
            <View style={{ paddingLeft: 25 }}>
              {data.mathStrategies.map((item, index) => {
                return (
                  <Link key={index} style={styles.link} src={item.url}>
                    {item.title}
                  </Link>
                );
              })}
            </View>
          </View>
        )}
      </View>
    </Page>
  );
};

export default MainPage;

const Card = (props) => {
  const invert = props.skill.title === "Executive Functions";
  const skill = props.skill;
  const highlight = skill.highlight;
  const orderedSubskills = skill.subskills.sort((a, b) => {
    return a.order - b.order;
  });
  return (
    <View
      style={{
        backgroundColor: "#fff",
        flex: 1,
        height: highlight ? 235 : 200,
        marginLeft: props.index < 1 ? 15 : 7.5,
        marginRight: props.index > 1 ? 15 : 7.5,
        alignSelf: "flex-end",
        borderRadius: 10,
        borderWidth: 3,
        borderColor: highlight ? "#fdcb6f" : "#dfe6ea",
      }}
    >
      <View style={{ backgroundColor: "#edf0f4", padding: 5 }}>
        <Text
          style={[
            props.styles.h2,
            {
              color: "#636f73",
              marginBottom: 3,
              fontFamily: skill.highlight
                ? "Montserrat-Bold"
                : "Montserrat-SemiBold",
            },
          ]}
        >
          {skill.title}
        </Text>
        <Text
          style={[
            props.styles.text,
            {
              color: skill.highlight ? "#f178a6" : "#008dd0",
              fontSize: 8,
              fontFamily: "Montserrat-SemiBold",
            },
          ]}
        >
          {skill.subtitle}
        </Text>
      </View>
      <View style={{ paddingTop: 10, flexGrow: 1 }}>
        {orderedSubskills.map((item, index) => {
          return (
            <SkillContainer
              key={index}
              skill={item}
              highlight={index === 0}
              styles={props.styles}
            />
          );
        })}
      </View>
      <View
        style={{
          width: "100%",
          height: 60,
          padding: 10,
        }}
      >
        <Text
          style={{
            fontFamily: skill.highlight
              ? "Montserrat-Bold"
              : "Montserrat-SemiBold",
            fontSize: 10,
            marginBottom: 5,
            color: skill.highlight ? "#f178a6" : "#008dd0",
          }}
        >
          Top Strategy
        </Text>
        <Link
          wrap={false}
          style={[
            props.styles.text,
            { color: "#231f20", textDecorationColor: "#fff" },
          ]}
          src={orderedSubskills[invert ? orderedSubskills.length - 1 : 0].link}
        >
          {orderedSubskills[invert ? orderedSubskills.length - 1 : 0].linkTitle}
        </Link>
      </View>
    </View>
  );
};

const SkillContainer = (props) => {
  const iconName = getIconForTitle(props.skill.title);
  let title = props.skill.title;

  if (title === "Non-verbal Reasoning") {
    title = "Visual Reasoning";
  }
  const text = (
    <Text
      style={[
        props.styles.text,
        {
          color: "#636f73",
          fontFamily: props.highlight
            ? "Montserrat-Bold"
            : "Montserrat-Regular",
          size: props.highlight ? 10 : 8,
        },
      ]}
    >
      {title}
    </Text>
  );

  const icon = (
    <Image
      src={"/assets/icons/" + iconName + ".png"}
      style={{ width: 13, opacity: props.highlight ? 1.0 : 0.75 }}
    />
  );
  return (
    <View
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {text}
      {icon}
    </View>
  );
};

const getIconForTitle = (val) => {
  const title = val.toLowerCase();

  const iconTable = [
    { title: "verbal reasoning", key: "verbal_reasoning", iconName: "book" },
    {
      title: "non-verbal reasoning",
      key: "non_verbal_reasoning",
      iconName: "chart",
    },
    {
      title: "visual reasoning",
      key: "non_verbal_reasoning",
      iconName: "chart",
    },
    { title: "spatial reasoning", key: "spatial_reasoning", iconName: "gyro" },
    { title: "visual memory", key: "visual_memory", iconName: "camera" },
    { title: "verbal memory", key: "verbal_memory", iconName: "cabinet" },
    { title: "short-term memory", key: "short_term_memory", iconName: "list" },
    { title: "flexible thinking", key: "flexible_thinking", iconName: "faces" },
    { title: "pacing", key: "pacing", iconName: "hourglass" },
    { title: "faster pace", key: "pacing", iconName: "hourglass" },
    { title: "slower pace", key: "pacing", iconName: "hourglass" },
    { title: "attention", key: "attention", iconName: "target" },
  ];

  let value = "book";
  for (let i = 0; i < iconTable.length; i++) {
    if (title === iconTable[i].title) {
      value = iconTable[i].iconName;
    }
  }
  return value;
};
