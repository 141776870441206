import React from "react";
import { Link, Text, View } from "@react-pdf/renderer";

const MPLink = ({ children, src }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        paddingVertical: 2,
        width: "100%",
      }}
    >
      <Text
        style={{
          color: "#008dd0",
          fontFamily: "Montserrat-Bold",
          paddingRight: 5,
          fontSize: 10,
        }}
      >
        +
      </Text>
      <Link
        style={{
          flex: 1,
          fontSize: 10,
          color: "#008dd0",
          fontFamily: "Montserrat-Regular",
          textDecorationColor: "#979A9C",
        }}
        src={src}
      >
        {children}
      </Link>
    </View>
  );
};

export default MPLink;
