const helpers = {};

helpers.help = () => {
  console.log("HELP!");
};

helpers.getPercentileCategory = (percentile) => {
  // 710F14, FD7421, FEB634, 207B41, 1D63AF
  let val = { color: "#710F14", text: "low" };
  if (percentile >= 0.8) {
    val = { color: "#1D63AF", text: "high" };
  } else if (percentile >= 0.6) {
    val = { color: "#207B41", text: "high avg" };
  } else if (percentile >= 0.4) {
    val = { color: "#FEB634", text: "mid" };
  } else if (percentile >= 0.2) {
    val = { color: "#FD7421", text: "low avg" };
  }

  return val;
};

// https://www.math.ucla.edu/~tom/distributions/normal.html
helpers.getPercentileForZScore = (zScore) => {
  console.log("zScore=", zScore);
  const t = 1 / (1 + 0.2316419 * Math.abs(zScore));
  const d = 0.3989423 * Math.exp((-zScore * zScore) / 2);
  let prob =
    d *
    t *
    (0.3193815 +
      t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
  if (zScore > 0) {
    prob = 1 - prob;
  }
  return Math.round(prob * 100) / 100;
};

helpers.getGradeCategory = (grade) => {
  let cat = "elem";
  if (grade >= 6 && grade < 9) {
    cat = "middle";
  }
  if (grade >= 9) {
    cat = "high";
  }

  return cat;
};

helpers.getFloor = (subject, gradeGroup) => {
  const table = [
    { subject: "Reading", gradeGroup: "elem", floor: 0.15 }, // (0.21 fudge)
    { subject: "Reading", gradeGroup: "middle", floor: 0.15 }, // from More Regressions spreadsheet
    { subject: "Reading", gradeGroup: "high", floor: 0.15 }, // (0.33 fudge)
    { subject: "Mathematics", gradeGroup: "elem", floor: 0.09 },
    { subject: "Mathematics", gradeGroup: "middle", floor: 0.1 }, // -0.11 (0.1 fudge)
    { subject: "Mathematics", gradeGroup: "high", floor: 0.14 },
  ];
  const result = table.filter(
    (item) => item.subject === subject && item.gradeGroup === gradeGroup
  )[0];
  if (result) {
    return result.floor;
  } else {
    return 0;
  }
};

helpers.FAKE_LEARNING_PROFILE_DATA_2 = {
  student: {
    firstName: "Luke",
    lastName: "Lecheler",
    grade: 12,
    school: "Test School",
  },
  skills: [
    {
      title: "Long-term Memory",
      subtitle: "Organizing & Completing Work",
      order: 1,
      subskills: [
        { title: "Visual Memory", key: "visual_memory", order: 0 },
        {
          title: "Verbal Memory",
          key: "verbal_memory",
          order: 1,
        },
      ],
      topStratey: {
        title: "Use Your Stronger Visual Memory",
        url: "https://www.google.com/",
      },
    },
    {
      title: "Executive Functions",
      subtitle: "Remembering Information",
      order: 2,
      subskills: [
        { title: "Attention", key: "attention", order: 0 },
        { title: "Short-term Memory", key: "short_term_memory", order: 1 },
        { title: "Flexible Thinking", key: "flexible_thinking", order: 2 },
        { title: "Pacing", key: "pacing", order: 3 },
      ],
      topStratey: {
        title: "Awareness of Pacing",
        url: "https://www.google.com/",
      },
    },
    {
      title: "Complex Reasoning",
      subtitle: "Understanding New Concepts",
      order: 0,
      subskills: [
        { title: "Verbal Reasoning", key: "verbal_reasoning", order: 0 },
        {
          title: "Visual Reasoning",

          key: "non_verbal_reasoning",
          order: 1,
        },
        { title: "Spatial Reasoning", key: "spatial_reasoning", order: 2 },
      ],
      topStratey: {
        title: "Use Your Stronger Verbal Reasoning",
        url: "https://www.google.com/",
      },
    },
  ],
  languageArtsStrategies: [
    { title: "Fiction: Key Ideas and Details", url: "https://www.google.com/" },
    {
      title: "Non-fiction: Informational Text",
      url: "https://www.google.com/",
    },
    {
      title: "Fiction: Language, Craft, Structure",
      url: "https://www.google.com/",
    },
    { title: "Vocabulary", url: "https://www.google.com/" },
  ],
  mathStrategies: [
    { title: "Geometry", url: "https://www.google.com/" },
    {
      title: "Operations and Algebraic Thinking",
      url: "https://www.google.com/",
    },
    { title: "Statistics and Probability", url: "https://www.google.com/" },
    { title: "Real and Complex Numbers", url: "https://www.google.com/" },
  ],
};

helpers.FAKE_LEARNING_PROFILE_DATA = {
  status: 200,
  student: {
    customer_id: "20066",
    firstName: "Simone",
    lastName: "Majekodunmi",
    gender: "female",
    grade: "middle/high",
    birthdayForTesting: "2008-10-13",

    school: "Test School",
    age: 12,
    mpTestDate: {
      date: "2019-10-29 05:05:12",
      timezone_type: 3,
      timezone: "UTC",
    },
  },
  skills: [
    {
      title: "Complex Reasoning",
      subtitle: "Understanding New Concepts",
      highlight: false,
      subskills: [
        {
          title: "Spatial Reasoning",
          order: 0,
          score: "0.2877",
          linkTitle: "Use Your Stronger Spatial Perception",
          link: "https://my.mindprintlearning.com/product/11333",
        },
        {
          title: "Non-verbal Reasoning",
          order: 1,
          score: "-0.4406",
          linkTitle: "Use Your Stronger Abstract Reasoning",
          link: "https://my.mindprintlearning.com/product/11332",
        },
        {
          title: "Verbal Reasoning",
          order: 2,
          score: "-0.4606",
          linkTitle: "Use Your Stronger Verbal Reasoning",
          link: "https://my.mindprintlearning.com/product/11331",
        },
      ],
      topStrategy: [
        {
          title: "Use Your Stronger Visual Memory",
          url: "https://www.google.com/",
        },
      ],
    },
    {
      title: "Long-term Memory",
      subtitle: "Organizing & Completing Work",
      highlight: false,
      subskills: [
        {
          title: "Verbal Memory",
          order: 0,
          score: "0.3979",
          linkTitle: "Use Your Stronger Verbal Memory",
          link: "https://my.mindprintlearning.com/product/11334",
        },
        {
          title: "Visual Memory",
          order: 1,
          score: "0.1809",
          linkTitle: "Use Your Stronger Visual Memory",
          link: "https://my.mindprintlearning.com/product/11081",
        },
      ],
      topStrategy: [
        {
          title: "Use Your Stronger Visual Memory",
          url: "https://www.google.com/",
        },
      ],
    },
    {
      title: "Executive Functions",
      subtitle: "Remembering Information",
      highlight: true,
      subskills: [
        {
          title: "Short-term Memory",
          order: 0,
          score: "1.1175",
          linkTitle: "Get Organized: Student Checklist (A)",
          link: "https://my.mindprintlearning.com/product/12853",
        },
        {
          title: "Attention",
          order: 1,
          score: "0.4268",
          linkTitle: "Homework: Student Checklist (A)",
          link: "https://my.mindprintlearning.com/product/12867",
        },
        {
          title: "Flexible Thinking",
          order: 2,
          score: "-0.3000",
          linkTitle: "Strengthen Problem Solving and Decision Making",
          link: "https://my.mindprintlearning.com/product/12946",
        },
        {
          title: "Pacing",
          order: 3,
          score: "-0.3549",
          linkTitle: "Help Students Build Awareness of Pace",
          link: "https://my.mindprintlearning.com/product/11304",
        },
      ],
      topStrategy: [
        {
          title: "Awareness of Pacing",
          url: "https://www.google.com/",
        },
      ],
    },
  ],

  //  invalidSkills: null,
  invalidSkills: {
    LAN_Az: -1.0,
  },
  mathStrategies: [
    {
      title: "Geometry: Student Checklist (MH,B)",
      url: "https://my.mindprintlearning.com/product/12857",
    },
    {
      title: "Statistics and Probability: Student Checklist (MH,B)",
      url: "https://my.mindprintlearning.com/product/12922",
    },
    {
      title:
        "Equations, Expressions & Functions: Student Checklist (Operations & Algebraic Thinking MH,B)",
      url: "https://my.mindprintlearning.com/product/12925",
    },
  ],
  languageArtsStrategies: [
    {
      title: "Fiction: Student Checklist (B)",
      url: "https://my.mindprintlearning.com/product/12850",
    },
    {
      title: "Non-Fiction: Student Checklist (B)",
      url: "https://my.mindprintlearning.com/product/12859",
    },
    {
      title: "Writing Assignments: Student Checklist (B)",
      url: "https://my.mindprintlearning.com/product/12870",
    },
  ],
  selfeLessons: [
    {
      lesson: 3,
      title:
        "MindPrint Student Learner Profile: Discovering Your Reasoning Strengths",
      rule: "Strongest Reasoning Skill of 3",
      skill: "Verbal Reasoning",
      referenceList: [
        {
          boostReference: "boost/2",
          title: "Use Your Stronger Verbal Reasoning",
          link: "/boost/63kp",
        },
      ],
    },
    {
      lesson: 3,
      title:
        "MindPrint Student Learner Profile: Discovering Your Memory Strengths",
      rule: "Strongest Memory Skill of 2",
      skill: "Verbal Memory",
      referenceList: [
        {
          boostReference: "boost/5",
          title: "Use Your Stronger Verbal Memory",
          link: "/boost/pnj8",
        },
      ],
    },
    {
      lesson: 4,
      title: "Memorizing for Efficiency",
      rule: "Strongest Memory Skill of 2",
      skill: "Verbal Memory",
      referenceList: [
        {
          boostReference: "boost/6",
          title: "Space Out Studying",
          link: "/boost/4vxz",
        },
        {
          boostReference: "boost/7",
          title: "Say it Aloud to Remember",
          link: "/boost/wnjb",
        },
        {
          boostReference: "boost/8",
          title: "Quiz Yourself to Study",
          link: "/boost/d2ix",
        },
      ],
    },
    {
      lesson: 5,
      title: "Homework: Organizing for Success",
      rule: "Base it on the weakest of the 3 skills (Attention, Processing Speed, or Working Memory)",
      skill: "Attention",
      referenceList: [
        {
          boostReference: "boost/10",
          title: "Create a Homework Schedule",
          link: "/boost/jarx",
        },
        {
          boostReference: "boost/11",
          title: "Use a Planner",
          link: "/boost/tt61",
        },
        {
          boostReference: "boost/13",
          title: "Homework Checklist: Staying Organized",
          link: "/boost/4rj2",
        },
      ],
    },
    {
      lesson: 6,
      title: "Reading: Managing Challenging Texts",
      rule: "Stronger Reasoning Skill of 2",
      skill: "Verbal Reasoning",
      referenceList: [
        {
          boostReference: "boost/16",
          title: "Reread & Restate Out Loud",
          link: "/boost/8h9v",
        },
        {
          boostReference: "boost/17",
          title: "Annotate While Reading",
          link: "/boost/lgdm",
        },
      ],
    },
    {
      lesson: 7,
      title: "Pacing: There's no Prize for Finishing First",
      rule: "Processing Speed",
      skill: "Fast Pace",
      referenceList: [
        {
          boostReference: "boost/23",
          title: "Be Aware of Your Pacing - Working Too Fast",
          link: "/boost/kqf1",
        },
        {
          boostReference: "boost/24",
          title: "Check Your Math Work",
          link: "/boost/tv3g",
        },
        {
          boostReference: "boost/25",
          title: "Writing Checklist",
          link: "/boost/sw6k",
        },
      ],
    },
    {
      lesson: 8,
      title: "Math: Using Your Strengths in Problem Solving",
      rule: "Strongest Reasoning Skill of 3",
      skill: "Verbal Reasoning",
      referenceList: [
        {
          boostReference: "boost/26",
          title: "Talk Through Math Problems",
          link: "/boost/x3q3",
        },
        {
          boostReference: "boost/27",
          title: "Compare the Problem to One You Have Solved Before",
          link: "/boost/g5qf",
        },
      ],
    },
    {
      lesson: 9,
      title: "Focus: Paying Attention in Class",
      rule: "Same for everyone",
      skill: "NULL",
      referenceList: [
        {
          boostReference: "boost/30",
          title: "Strategies to Stay Focused in Class",
          link: "/boost/594p",
        },
      ],
    },
    {
      lesson: 10,
      title: "Test Taking: Optimizing Your Success",
      rule: "Processing Speed",
      skill: "Fast Pace",
      referenceList: [
        {
          boostReference: "boost/31",
          title: "Make a Test Plan",
          link: "/boost/16bp",
        },
        {
          boostReference: "boost/33",
          title: "'Throw Away' Test Anxiety",
          link: "/boost/r9sb",
        },
        {
          boostReference: "boost/34",
          title: "Check Work on Test",
          link: "/boost/ekuc",
        },
      ],
    },
  ],
};

helpers.FAKE_NWEA_DATA = {
  value: {
    customer_id: "41383",
    firstName: "Destany",
    lastName: "Bullard",
    gender: "female",
    grade: "10",
    school: "Denair USD",
    subject: "Reading",
    mpTestDate: {
      date: "2021-09-08 16:49:04.000000",
      timezone_type: 3,
      timezone: "UTC",
    },
    testDate: "Spring 2020-2021",
    ritScore: 193,
    ritPercentile: 0.04,
    skills: [
      {
        title: "Verbal Reasoning Accuracy",
        value: "-0.2021",
        skillRoot: "LAN",
        validity: 1,
        order: 0,
      },
      {
        title: "Verbal Reasoning Speed",
        value: "-3",
        skillRoot: "LAN",
        validity: 1,
        order: 1,
      },
      {
        title: "Abstract Reasoning Accuracy",
        value: "1.0410",
        skillRoot: "NVR",
        validity: 1,
        order: 2,
      },
      {
        title: "Verbal Memory Accuracy",
        value: "0.9644",
        skillRoot: "VMEM",
        validity: 1,
        order: 3,
      },
    ],
    validity: [
      {
        skillRoot: "SM",
        validity: 1,
      },
      {
        skillRoot: "ABF",
        validity: 1,
      },
      {
        skillRoot: "ATT",
        validity: 1,
      },
      {
        skillRoot: "WM",
        validity: 1,
      },
      {
        skillRoot: "SMEM",
        validity: 1,
      },
      {
        skillRoot: "VMEM",
        validity: 1,
      },
      {
        skillRoot: "NVR",
        validity: 0,
      },
      {
        skillRoot: "LAN",
        validity: 1,
      },
      {
        skillRoot: "SPA",
        validity: 1,
      },
      {
        skillRoot: "PROC",
        validity: 1,
      },
    ],
    subscores: [
      {
        name: "Literature",
        subCategoryKey: "lit_key",
        ritScore: 233,
        ritPercentile: 0.7,
        teachingStrategies: [
          {
            text: "Organizational Guide",
            url: "https://my.mindprintlearning.com/toolbox/41383/product/12267",
          },
          {
            text: "Stop & Summarize ",
            url: "https://my.mindprintlearning.com/toolbox/41383/product/11507",
          },
        ],
        checklist: {
          text: "Fiction Checklist (Cc)",
          url: "https://my.mindprintlearning.com/toolbox/41383/product/12926",
        },
        moreStrategies: {
          text: "More Strategies",
          url: "https://my.mindprintlearning.com/search?name=&academic_topics=219&cognitive_skills=&interests=&product_types=14&age_range_min=12&age_range_max=21&order_by=bestfit_score+desc&uses_recommendations=&pills=academic_topics%7C219.&page=1",
        },
      },
      {
        name: "Informational Text",
        subCategoryKey: "info_text",
        ritScore: 220,
        ritPercentile: 0.43,
        teachingStrategies: [
          {
            text: "Provide Context (Non-Fiction)",
            url: "https://my.mindprintlearning.com/toolbox/41383/product/10600",
          },
          {
            text: "Annotate While Reading",
            url: "https://my.mindprintlearning.com/toolbox/41383/product/12616",
          },
        ],
        checklist: {
          text: "Non-Fiction Checklist (Bb)",
          url: "https://my.mindprintlearning.com/toolbox/41383/product/12859",
        },
        moreStrategies: {
          text: "More Strategies",
          url: "https://my.mindprintlearning.com/search?name=non-fiction&academic_topics=&cognitive_skills=&interests=&product_types=14&age_range_min=12&age_range_max=21&order_by=bestfit_score+desc&uses_recommendations=&pills=",
        },
      },
      {
        name: "Vocabulary Acquisition and Use",
        subCategoryKey: "vocab",
        ritScore: 224,
        ritPercentile: 0.52,
        teachingStrategies: [
          {
            text: "Use Multi-modal Vocabulary Practice",
            url: "https://my.mindprintlearning.com/toolbox/41383/product/10976",
          },
          {
            text: "Extra Vocabulary Exposure",
            url: "https://my.mindprintlearning.com/toolbox/41383/product/11888",
          },
        ],
        checklist: {
          text: "Create Flashcards",
          url: "https://my.mindprintlearning.com/toolbox/41383/product/10277",
        },
        moreStrategies: {
          text: "More Strategies",
          url: "https://my.mindprintlearning.com/search?name=vocabulary&academic_topics=&cognitive_skills=&interests=&product_types=14&age_range_min=12&age_range_max=21&order_by=bestfit_score+desc&uses_recommendations=&pills=",
        },
      },
    ],
    scores: {
      datasetid: "21074",
      subjectid: "6130ae2290583",
      SM_A: "20",
      SM_Az: "0.0352",
      SM_S: "908.5",
      SM_Sz: "-1.5642",
      SM_NormSet: "BBL N9492 Norms by age 2014",
      SM_NormStrata: "Age 16-17",
      VMEM_A: "40",
      VMEM_Az: "0.9644",
      VMEM_S: "1633.000",
      VMEM_Sz: "-0.9688",
      VMEM_NormSet: "BBL N9492 Norms by age 2014",
      VMEM_NormStrata: "Age 16-17",
      ABF_A: "2.6897",
      ABF_Az: "0.3856",
      ABF_S: "2817",
      ABF_Sz: "-1.5537",
      ABF_NormSet: "BBL N9492 Norms by age 2014",
      ABF_NormStrata: "Age 16-17",
      LAN_A: "12",
      LAN_Az: "-0.2021",
      LAN_S: "19962",
      LAN_Sz: "-9.1688",
      LAN_NormSet: "BBL N9492 Norms by age 2014",
      LAN_NormStrata: "Age 16-17",
      NVR_A: "18",
      NVR_Az: "1.0410",
      NVR_S: "10602.5",
      NVR_Sz: "-0.8272",
      NVR_NormSet: "BBL N9492 Norms by age 2014",
      NVR_NormStrata: "Age 16-17",
      WM_A: "18",
      WM_Az: "-0.1618",
      WM_S: "572",
      WM_Sz: "-0.8320",
      WM_NormSet: "BBL N9492 Norms by age 2014",
      WM_NormStrata: "Age 16-17",
      ATT_A: "58",
      ATT_Az: "0.6863",
      ATT_S: "516.5",
      ATT_Sz: "-1.3828",
      ATT_NormSet: "BBL N9492 Norms by age 2014",
      ATT_NormStrata: "Age 16-17",
      SMEM_A: "16",
      SMEM_Az: "0.1377",
      SMEM_S: "2244.250",
      SMEM_Sz: "-1.0870",
      SMEM_NormSet: "BBL N9492 Norms by age 2014",
      SMEM_NormStrata: "Age 16-17",
      SPA_A: "14",
      SPA_Az: "0.8381",
      SPA_S: "11040",
      SPA_Sz: "-0.6564",
      SPA_NormSet: "BBL N9492 Norms by age 2014",
      SPA_NormStrata: "Age 16-17",
      exefun_A_z: "0.3034",
      exefun_S_z: "-1.2562",
      exefun_wiv_A_z: "0.4300",
      exefun_wiv_S_z: "0.3771",
      comcog_A_z: "0.5590",
      comcog_S_z: "-3.5508",
      comcog_wiv_A_z: "0.6669",
      comcog_wiv_S_z: "4.8661",
      mot_A_z: "0.0352",
      mot_S_z: "-1.5642",
      ciq_A_z: "0.4138",
      wiv_A_z: "0.4850",
      CPW_ER: "0",
      CPW_RTER: "",
      CPW_TP: "20",
      CPW_TN: "20",
      CPW_TPRT: "1456",
      CPW_TNRT: "1810",
      CPW_FP: "0",
      CPW_FN: "0",
      CPW_FPRT: "",
      CPW_FNRT: "",
      KSPVRTD_PC: "80.0000",
      KSPVRTD_RTER: "22955",
      PCET_CR: "39",
      PCET_ER: "19",
      PCET_RTER: "4423",
      PCET_CAT: "3",
      PCET_CAT1_TR: "10",
      PCET_CAT2_TR: "27",
      PCET_CAT3_TR: "21",
      PCET_ACC2: "2.6897",
      PCET_ACC2z: "0.3856",
      PMAT24_B_SI: "1",
      PMAT24_B_RTER: "17856",
      PMAT24_B_RTTO: "10667",
      SLNB2_TP: "28",
      SLNB2_FP: "1",
      SLNB2_RTC: "473",
      SLNB2_TP0: "10",
      SLNB2_FP0: "0",
      SLNB2_RTC0: "459",
      SLNB2_FPRT0: "",
      SLNB2_TP1: "10",
      SLNB2_FP1: "0",
      SLNB2_RTC1: "491.5",
      SLNB2_FPRT1: "",
      SLNB2_TP2: "8",
      SLNB2_FP2: "1",
      SLNB2_RTC2: "702.5",
      SLNB2_FPRT2: "21",
      SLNB2_LRNR: "4",
      SLNB2_LRR: "2",
      SLNB2_FRNR: "0",
      SCPN_TP: "30",
      SCPN_FP: "2",
      SCPN_TN: "58",
      SCPN_FN: "0",
      SCPN_TPRT: "493",
      SCPN_FPRT: "600.5",
      SCPL_TP: "28",
      SCPL_FP: "6",
      SCPL_TN: "54",
      SCPL_FN: "2",
      SCPL_TPRT: "538.5",
      SCPL_FPRT: "522.5",
      SCPT_FRNR: "2",
      SCPT_LRNR: "8",
      SCPT_REV_FP: "7",
      SCPT_REV_FPRT: "547",
      SCPT_REV_FN: "13",
      SVOLT_ER: "4",
      SVOLT_RTER: "2959.5",
      SVOLT_TP: "8",
      SVOLT_TN: "8",
      SVOLT_TPRT: "2286.5",
      SVOLT_TNRT: "2202",
      SVOLT_FP: "2",
      SVOLT_FN: "2",
      SVOLT_FPRT: "6331.5",
      SVOLT_FNRT: "2959.5",
      SVOLT_LRNR: "0",
      VSPLOT24_ER: "10",
      VSPLOT24_RTER: "9318.5",
      VSPLOT24_TOT_RT: "296691",
      VSPLOT24_OFF: "10",
      VSPLOT24_SUM_EXCESS: "71",
      VSPLOT24_SUM_DEFICIT: "1",
      VSPLOT24_SUM_DEG_OFF: "45",
      exefun_age: "14.3161",
      comcog_age: "13.2155",
      CPW_LRSR: "3",
      CPW_CNT_200: "0",
      PCET_LRSR: "3",
      KSPVRTD_LRSR: "2",
      KSPVRTD_CNT_750: "0",
      SLNB2_CNT_200: "1",
      SVOLT_LRSR: "3",
      SVOLT_CNT_200: "0",
      SVOLT_NR: "0",
      SM_z_eff: "-0.7645",
      VMEM_z_eff: "-0.0022",
      ABF_z_eff: "-0.5840",
      LAN_z_eff: "-4.6855",
      NVR_z_eff: "0.1069",
      WM_z_eff: "-0.4969",
      ATT_z_eff: "-0.3483",
      SMEM_z_eff: "-0.4747",
      SPA_z_eff: "0.0909",
      PROC_S_z: "-1.0676",
    },
  },
};

module.exports = helpers;
