import React from "react";

import { Page, Text, View, Image } from "@react-pdf/renderer";

const Glossary = ({ styles }) => {
  const FULL = 520;

  return (
    <Page size="A4" style={styles.page}>
      <View
        style={[
          styles.text,
          {
            width: FULL,
          },
        ]}
      >
        <Text style={styles.h1}>Glossary of Terms</Text>
        <Text style={styles.glossaryHeading}>COMPLEX REASONING</Text>
        <Text style={styles.italicText}>
          Complex Reasoning is your ability to understand and analyze new
          information, and solve problems. You take in new information in a
          variety of ways, most often reading, hearing, seeing, and visualizing.
        </Text>

        <GlossaryIcon iconName="book" title="Verbal Reasoning" styles={styles}>
          <Text style={styles.text}>
            Understanding and applying what you read and hear. Biggest impact:
            All subjects, since most academic topics depend on reading,
            listening, and writing, but most important in reading comprehension
            and word problems
          </Text>
        </GlossaryIcon>
        <GlossaryIcon
          styles={styles}
          iconName="chart"
          title="Visual Reasoning (Abstract Reasoning)"
        >
          <Text style={styles.text}>
            Understanding non-language-based information, including images,
            numbers, shapes, patterns, and challenging concepts. Biggest impact:
            High school math and science; also main idea and inference questions
            in English and social studies
          </Text>
        </GlossaryIcon>
        <GlossaryIcon
          styles={styles}
          iconName="gyro"
          title="Spatial Reasoning (Spatial Perception)"
        >
          <Text style={styles.text}>
            Visualizing and understanding how objects relate in space. Biggest
            impact: Geometry, graphs and charts, science, keeping math and
            writing work neat, reading efficiency, grammar, art and design,
            especially anything in 3-D; Speed on standardized tests
          </Text>
        </GlossaryIcon>

        <Text style={styles.glossaryHeading}>LONG-TERM MEMORY</Text>

        <Text style={styles.italicText}>
          Long-Term Memory is how efficiently you memorize new information and
          recall it over time. Weaker memory can be offset by spaced practice
          and effective strategy use.
        </Text>
        <GlossaryIcon styles={styles} iconName="cabinet" title="Verbal Memory">
          <Text style={styles.text}>
            Remembering what you read or hear. Biggest impact: Subject-specific
            vocabulary, remembering details of what you read, remembering the
            specifics of class discussions
          </Text>
        </GlossaryIcon>

        <GlossaryIcon styles={styles} iconName="camera" title="Visual Memory">
          <Text style={styles.text}>
            Remembering formulas, figures, and pictures or other
            non-language-based information. Biggest impact: Geometry, math
            formulas, science graphs and charts, geography, colors, and patterns
          </Text>
        </GlossaryIcon>

        <Text style={styles.glossaryHeading}>EXECUTIVE FUNCTIONS & SPEED</Text>
        <Text style={styles.italicText}>
          Executive Functions & Speed refer to how accurately, efficiently, and
          systematically you can complete your assignments (regardless of how
          well you know and understand the information).
        </Text>

        <GlossaryIcon styles={styles} iconName="target" title="Attention">
          <Text style={styles.text}>
            Sustaining focus to work accurately and efficiently. Biggest impact:
            Topics you find less interesting, completing work in the allotted
            time, avoiding careless mistakes, participating throughout class,
            getting started and finishing your homework
          </Text>
        </GlossaryIcon>

        <GlossaryIcon
          styles={styles}
          iconName="list"
          title="Short-term Memory (Working Memory)"
        >
          <Text style={styles.text}>
            Juggling information in your head for multi-step problem solving or
            tasks. Biggest impact: Following directions, avoiding careless
            mistakes, keeping organized, mental math, note taking, remembering
            ideas long enough to participate in class discussion or write them
            down
          </Text>
        </GlossaryIcon>

        <GlossaryIcon
          styles={styles}
          iconName="faces"
          title="Flexible Thinking"
        >
          <Text style={styles.text}>
            Adapting to unfamiliar information or situations. Biggest impact:
            Understanding “trick” questions, making decisions, adapting in new
            situations, working in groups, creative problem solving
          </Text>
        </GlossaryIcon>

        <GlossaryIcon
          styles={styles}
          iconName="hourglass"
          title="Pace (Processing Speed)"
        >
          <Text style={styles.text}>
            Responding by balancing speed and accuracy. Biggest impact:
            Answering too fast and making careless errors, responding slower and
            having difficulty finishing in time or keeping up with the class
          </Text>
        </GlossaryIcon>

        <View
          style={{
            display: "flex",
            alignItems: "right",
            marginTop: 20,
          }}
        >
          <Image src="/assets/mp-logo-dark.png" style={{ width: 110 }} />
        </View>
      </View>
    </Page>
  );
};

const GlossaryIcon = (props) => {
  return (
    <View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Image
          src={"/assets/icons/" + props.iconName + ".png"}
          style={{ height: 10, marginRight: 5, marginTop: 5 }}
        />
        <Text style={props.styles.glossaryBold}>{props.title}</Text>
      </View>
      <Text style={props.styles.text}>{props.children}</Text>
    </View>
  );
};

export default Glossary;
