import React from "react";
import { StyleSheet, View, Image } from "@react-pdf/renderer";

import LearningPlanLogo from "./LearningPlanLogo";
import StudentInfoHeader from "./StudentInfoHeader";

const Header = (props) => {
	// Font.register({
	// 	family: "Montserrat-Regular",
	// 	src: "/fonts/Montserrat-Regular.ttf",
	// });

	// Font.register({
	// 	family: "Montserrat-Bold",
	// 	src: "/fonts/Montserrat-Bold.ttf",
	// });

	// Font.register({
	// 	family: "Montserrat-Italic",
	// 	src: "/fonts/Montserrat-Italic.ttf",
	// });

	// Font.register({
	// 	family: "Montserrat-SemiBold-Italic",
	// 	src: "/fonts/Montserrat-SemiBoldItalic.ttf",
	// });

	const styles = StyleSheet.create({
		container: {
			width: props.width,
			//	backgroundColor: "beige",
		},
		imageContainer: {
			display: "flex",
			justifyContent: "space-between",
			flexDirection: "row",
		},
		studentInfoContainer: {
			fontSize: 12,
			opacity: 0.7,
			marginTop: 20,
			marginBottom: 20,
		},
	});

	return (
		<View style={styles.container}>
			<View style={styles.imageContainer}>
				<Image src="/assets/mp-logo.png" style={{ height: 30 }} />
				<Image src="/assets/mapGrowth-logo.png" style={{ height: 30 }} />
			</View>
			<View style={styles.imageContainer}>
				<StudentInfoHeader
					firstName={props.firstName}
					lastName={props.lastName}
					grade={props.grade}
					school={props.school}
				/>
				<LearningPlanLogo subject={props.subject} />
			</View>
		</View>
	);
};

export default Header;

/*
<Text style={styles.label}>Grade: {stub.grade}</Text>
<Text style={styles.label}>{stub.school}</Text>

*/
