import React from "react";
import { StyleSheet, Text, Font, View, Image } from "@react-pdf/renderer";

import {
  getPercentileCategory,
  getPercentileForZScore,
  getFloor,
  getGradeCategory,
} from "../helpers";

const BarChart = (props) => {
  Font.register({
    family: "Montserrat-Bold",
    src: "fonts/Montserrat-Bold.ttf",
  });

  const BAR_CHART_CONTAINER_WIDTH = 440;
  const BAR_CHART_WIDTH = BAR_CHART_CONTAINER_WIDTH - 200;

  const BAR_CHART_HEIGHT = props.height ? props.height : 85;
  const BAR_WIDTH = BAR_CHART_WIDTH / 4;

  const L1_WIDTH =
    (BAR_CHART_CONTAINER_WIDTH - BAR_CHART_WIDTH) / 2 +
    BAR_WIDTH +
    BAR_WIDTH / 2;
  const L2_WIDTH =
    (BAR_CHART_CONTAINER_WIDTH - BAR_CHART_WIDTH) / 2 + BAR_WIDTH / 2;

  const floor = getFloor(props.subject, getGradeCategory(props.grade));

  let mpPercentile = props.mpPercentile;
  if (mpPercentile < floor) {
    mpPercentile = floor;
  }
  let mpScore = -BAR_CHART_HEIGHT * mpPercentile - 30;

  const styles = StyleSheet.create({
    barChartContainer: {
      width: BAR_CHART_CONTAINER_WIDTH,
      height: BAR_CHART_HEIGHT + 65,
      marginTop: 20,
      marginBottom: 20,
      //	backgroundColor: "#EDF4F6",
      display: "flex",
      alignItems: "center",
      //	padding: 20,
      // justifyContent: "center",
    },
    barChart: {
      display: "flex",
      flexDirection: "row",
      width: BAR_CHART_WIDTH,
      height: BAR_CHART_HEIGHT,
      // backgroundColor: "beige",
      // borderBottomColor: "#797171",
      // borderBottomStyle: "solid",
      // borderBottomWidth: 2,
      // marginLeft: -10,
    },
    barChartBar: {
      width: BAR_CHART_WIDTH / 4 - 10,
      backgroundColor: "#f37576",
      opacity: 1.0,
      marginLeft: 5,
      marginRight: 5,
      alignSelf: "flex-end",
      justifyContent: "flex-end",
    },
    barChartLabel: {
      fontSize: 8,
      fontFamily: "Montserrat-Light",
      hyphens: "none",
    },
    pill: {
      color: "white",
      backgroundColor: "#FEB634",
      width: 50,
      padding: 3,
      // height: 20,
      // display: "flex",
      // alignItems: "center",
      //   justifyContent: "center",
      textAlign: "center",
      fontSize: 9,

      borderRadius: 50,
    },
  });

  return (
    <View style={styles.barChartContainer}>
      <View
        style={{
          width: "100%",
          height: 30,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: L1_WIDTH,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Text style={[styles.barChartLabel, { textAlign: "right" }]}>
            {props.skills[1].title}
            {props.skills[1].validity === 0 ? " *" : ""}
          </Text>
        </View>
        <View style={{ width: BAR_WIDTH }} />
        <View
          style={{
            width: L1_WIDTH,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Text style={[styles.barChartLabel, { textAlign: "left" }]}>
            {props.skills[2].title}
            {props.skills[2].validity === 0 ? " *" : ""}
          </Text>
        </View>
      </View>
      <View
        style={{
          width: "100%",
          height: 30,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: L2_WIDTH,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Text style={[styles.barChartLabel, { textAlign: "right" }]}>
            {props.skills[0].title}
            {props.skills[0].validity === 0 ? " *" : ""}
          </Text>
        </View>
        <View
          style={{
            width: BAR_WIDTH + 0.5,
            borderStyle: "solid",
            borderColor: "#9D9E9E",
            borderRightWidth: 1,
          }}
        />
        <View
          style={{
            width: BAR_WIDTH + 0.5,
            borderStyle: "solid",
            borderColor: "#9D9E9E",
            borderRightWidth: 1,
            marginLeft: -0.5,
          }}
        />
        <View style={{ width: BAR_WIDTH - 0.5 }} />

        <View
          style={{
            width: L2_WIDTH,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Text style={[styles.barChartLabel, { textAlign: "left" }]}>
            {props.skills[3].title}
            {props.skills[3].validity === 0 ? " *" : ""}
          </Text>
        </View>
      </View>

      <View style={styles.barChart}>
        {props.skills.map((item, index) => {
          let score = getPercentileForZScore(item.value) + floor;

          console.log("Score = ", score);
          if (score > 1) score = 1;

          console.log("bar score = ", score);
          return (
            <View
              key={index}
              style={{
                height: BAR_CHART_HEIGHT,
                display: "flex",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: (1 - score) * BAR_CHART_HEIGHT,
                  width: 1,
                  //	marginBottom: 4, // gap between label line and bar
                  backgroundColor: "#9D9E9E",
                }}
              />
              <View
                style={[
                  styles.barChartBar,
                  {
                    height: score * BAR_CHART_HEIGHT,
                    backgroundColor:
                      item.validity === 0 ? "#dfe6ea" : "#f37576",
                  },
                ]}
              />
            </View>
          );
        })}
      </View>
      <View
        style={{
          backgroundColor: "#797171",
          width: BAR_CHART_WIDTH - 10,
          height: 2,
        }}
      />
      <View
        style={{
          marginTop: 5,
          //		backgroundColor: "beige",
          width: BAR_CHART_WIDTH,
          display: "flex",
          flexDirection: "row",
        }}
      >
        {props.skills.map((item, index) => {
          return (
            <Text
              key={index}
              style={{
                textAlign: "center",
                width: BAR_CHART_WIDTH / 4 - 10,
                marginLeft: 5,
                marginRight: 5,
                color: "#797171",
                // opacity: 0.65,
                fontSize: 9,
                fontFamily: "Montserrat-Bold",
              }}
            >
              {index + 1}
            </Text>
          );
        })}
      </View>
      <View
        style={{
          width: BAR_CHART_WIDTH + 130,
          marginTop: mpScore, // score
          marginLeft: 40,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View>
          <Image src="/assets/mp-brain.png" style={{ width: 25 }} />
        </View>
        <View
          style={{
            height: 1,
            flex: 1,
            //	backgroundColor: getPercentileCategory(props.mpPercentile).color,
            //	borderBottomStyle: "dashed",
            borderBottomColor: getPercentileCategory(props.mpPercentile).color,
            borderBottomWidth: 1,
            marginLeft: 10,
            opacity: 0.75,
          }}
        />
        <View
          style={[
            styles.pill,
            {
              width: 60,
              backgroundColor: getPercentileCategory(props.mpPercentile).color,
            },
          ]}
        >
          <Text
            style={[
              styles.score,
              {
                fontSize: 9,
              },
            ]}
          >
            {getPercentileCategory(props.mpPercentile).text}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default BarChart;
