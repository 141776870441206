import React from "react";
import { Font } from "@react-pdf/renderer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NWEA from "./reports/NWEA";
import LearningProfile from "./reports/learningProfile/LearningProfile";

import "./App.scss";

const DEV = process.env.REACT_APP_MODE === "development";
const VERSION = "1.0.0";

class App extends React.Component {
  componentDidMount() {
    console.log("Mounting Report Engine v." + VERSION);

    // Tell parent we are ready
    window.parent.postMessage("ready", "*");
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/nwea">
              <NWEA dev={DEV} />
            </Route>

            <Route path="/learningProfile">
              <LearningProfile dev={DEV} />
            </Route>
            <Route path="/">
              <div>
                Report Engine v.{VERSION}
                {DEV ? " [dev]" : ""}
              </div>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}
// Disable hyphens
// Font.registerHyphenationCallback((words) => words.map((word) => [word]));

// Register fonts for PDFs
Font.register({
  family: "Montserrat-Regular",
  src: "/fonts/Montserrat-Regular.ttf",
});

Font.register({
  family: "Montserrat-Black",
  src: "/fonts/Montserrat-Black.ttf",
});

Font.register({
  family: "Montserrat-Bold",
  src: "/fonts/Montserrat-Bold.ttf",
});

Font.register({
  family: "Montserrat-BoldItalic",
  src: "/fonts/Montserrat-BoldItalic.ttf",
});

Font.register({
  family: "Montserrat-SemiBold",
  src: "/fonts/Montserrat-SemiBold.ttf",
});

Font.register({
  family: "Montserrat-Italic",
  src: "/fonts/Montserrat-Italic.ttf",
});

Font.register({
  family: "Montserrat-SemiBold-Italic",
  src: "/fonts/Montserrat-SemiBoldItalic.ttf",
});

Font.register({
  family: "Montserrat-Light",
  src: "/fonts/Montserrat-Light.ttf",
});

export default App;
