import React from "react";
import { Text, View } from "@react-pdf/renderer";

const LearningPlanLogo = (props) => {
  let subject = props.subject.toUpperCase();
  if (subject === "MATHEMATICS") subject = "MATH";
  return (
    <View
      style={{
        textAlign: "right", // props.align ? props.align : "right",
        width: 120,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          color: "#6E6F72",
          fontFamily: "Montserrat-Regular",
          fontSize: props.size === "small" ? 12 : 15,
        }}
      >
        Learning Plan
      </Text>
      <Text
        style={{
          color: "#464648",
          fontFamily: "Montserrat-Bold",
          fontSize: props.size === "small" ? 15 : 18,
          marginTop: -4,
        }}
      >
        {subject}
      </Text>
    </View>
  );
};

export default LearningPlanLogo;
