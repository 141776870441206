import React from "react";
import { Page, Text, View } from "@react-pdf/renderer";

import Link from "../../components/Link";

const SelfePage = ({ data, styles }) => {
  const FULL = 520;

  return (
    <Page size="A4" style={styles.page}>
      <View style={{ width: FULL }}>
        <Text style={[styles.h1, { fontSize: 14, marginBottom: 15 }]}>
          Personalized Recommendations for {data.student.firstName}
        </Text>
        <Text style={[styles.text, { marginBottom: 15 }]}>
          Here's a prioritized list of strategies to get you started by subject
          and skill based on your MindPrint Profile. You can find many more by
          searching your Toolbox.
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#008dd0",
            alignItems: "center",
            padding: "10px 20px",
          }}
        >
          <View style={{ flex: 2 }}>
            <Text style={[styles.semiBold, { fontSize: 11, color: "#fff" }]}>
              Subject
            </Text>
          </View>
          <View style={{ flex: 3, paddingLeft: 20 }}>
            <Text style={[styles.semiBold, { fontSize: 11, color: "#fff" }]}>
              Recommended Strategies
            </Text>
          </View>
        </View>
        {data.selfeLessons.map((lesson, i) => {
          return (
            <Row
              key={"selfe-lesson-" + i}
              index={i}
              lesson={lesson}
              styles={styles}
            />
          );
        })}
      </View>
    </Page>
  );
};

export default SelfePage;

const Row = ({ index, lesson, styles }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: index % 2 === 0 ? "#FFFBFB" : "#FDE9EB",
        alignItems: "center",
        padding: "15px 20px",
      }}
    >
      <View style={{ flex: 2 }}>
        <Text
          style={{
            fontSize: 10,
            color: "#565656",
            fontFamily: "Montserrat-Italic",
          }}
        >
          {lesson.title}
        </Text>
      </View>
      <View style={{ flex: 3, paddingLeft: 20 }}>
        {lesson.referenceList.map((reference, i) => {
          return (
            <Link
              key={"lesson-reference-key-" + i}
              style={[styles.link, { fontSize: 10, color: "#008dd0" }]}
              src={reference.link}
            >
              {reference.title}
            </Link>
          );
        })}
      </View>
    </View>
  );
};

/* <Text style={[styles.h2, { fontSize: 10 }]}>{lesson.title}</Text>
              <Text
                style={[
                  styles.text,
                  {
                    fontSize: 8,
                    fontFamily: "Montserrat-Italic",
                    marginBottom: 10,
                  },
                ]}
              >
                SELfe Lesson {lesson.lesson}
              </Text>
              <View>
                {lesson.referenceList.map((reference, i) => {
                  return (
                    <Link
                      key={"lesson-reference-key-" + i}
                      style={[styles.link, { marginBottom: 5, fontSize: 9 }]}
                      src={process.env.REACT_APP_STRATEGY_URL + reference.link}
                    >
                      {reference.title}
                    </Link>
                  );
                })}
              </View> */
