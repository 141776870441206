import React from "react";
import { Font, Document, StyleSheet, PDFViewer } from "@react-pdf/renderer";

import { FAKE_LEARNING_PROFILE_DATA } from "../../helpers";

import MainPage from "./MainPage";

import Loader from "../../components/Loader";
import SelfePage from "./SelfePage";
import Glossary from "./Glossary";

Font.registerHyphenationCallback((word) => [word]);

class LearningProfile extends React.Component {
  state = {
    data: null,
    ready: false,
  };
  componentDidMount() {
    // receive message from parent
    window.addEventListener("message", this.receiveMessage, false);

    if (this.props.dev) {
      // simulate loading data from api
      if (this.props.dev) {
        setTimeout(() => {
          this.receiveData(FAKE_LEARNING_PROFILE_DATA);
        }, 1000);
      }
    }
    console.log("LEARNING PROFILE MOUNT!");
  }

  receiveMessage = (event) => {
    if (event.data !== "") {
      if (event.data.eventName === "sendScores") {
        console.log("sendscores = ", event.data.value);
        this.receiveData(event.data.value);
      }
    }
  };

  receiveData = (data) => {
    this.setState({ data: data, ready: true });
  };

  render() {
    const data = this.state.data; // FAKE_LEARNING_PROFILE_DATA; // this.props.data;
    console.log("data = ", data);

    if (!this.state.ready) {
      return (
        <div
          key="loader"
          style={{
            transition: "all 0.5s ease",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      );
    } else {
      return (
        <PDFViewer
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Document>
            <MainPage data={data} styles={styles} />
            {data.selfeLessons && <SelfePage data={data} styles={styles} />}
            <Glossary styles={styles} />
          </Document>
        </PDFViewer>
      );
    }
  }
}

export default LearningProfile;

// Styles

const FULL = 520;

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  page: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    marginTop: 30,
  },
  h1: {
    color: "#414042",
    fontFamily: "Montserrat-Bold",
    fontSize: 14,
    // width: FULL,
    //  marginBottom: 5,
  },
  h2: {
    color: "#008dd0",
    fontFamily: "Montserrat-SemiBold",
    fontSize: 11,
    marginBottom: 5,
    textAlign: "left",
  },
  semiBold: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 11,
    color: "#565656",
  },
  glossaryHeading: {
    color: "#008dd0",
    fontFamily: "Montserrat-SemiBold",
    fontSize: 14,
    marginTop: 15,
    marginBottom: 5,
    textAlign: "left",
  },
  glossaryBold: {
    color: "#008dd0",
    fontFamily: "Montserrat-SemiBold",
    fontSize: 11,
    marginTop: 10,
    marginBottom: 5,
    textAlign: "left",
  },
  text: {
    fontFamily: "Montserrat-Regular",
    fontSize: 10,
    color: "#1e272e",
    // opacity: 0.65,
  },
  italicText: {
    fontFamily: "Montserrat-Italic",
    fontSize: 10,
    color: "#1e272e",
    // opacity: 0.65,
  },
  boldText: {
    fontFamily: "Montserrat-Bold",
    fontSize: 10,
    color: "#1e272e",
  },
  line: {
    width: FULL,
    height: 1,
    backgroundColor: "#1e272e",
    marginTop: 5,
    marginBottom: 5,
  },
  sup: {
    fontSize: 5,
    lineHeight: 5,
    verticalAlign: "top",
    // marginTop: -5,
  },
  topPanel: {
    width: FULL,
    height: 110,
    backgroundColor: "#f7f6f7",
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  overallPanel: {
    width: 210,
    textAlign: "center",
    padding: 20,
    height: "100%",
    backgroundColor: "#dfe6ea",
  },
  subscorePanel: {
    backgroundColor: "#D9E6EB",
    //   height: 270,
    width: 240,
    borderRadius: 10,
    overflow: "hidden",
  },
  label: {
    fontFamily: "Montserrat-Regular",
    fontSize: "11pt",
  },
  scoreContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    marginTop: 10,
  },
  scoreLabel: {
    fontFamily: "Montserrat-Italic",
    fontSize: 9,
    textAlign: "left",
    paddingTop: 3,
  },
  logo: {
    width: 120,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  subscoreContainer: {
    display: "flex",
    flexDirection: "row",
  },
  panel: {
    backgroundColor: "#D9E6EB",
    borderRadius: 10,
    marginRight: 10,
  },
  pill: {
    color: "white",
    backgroundColor: "#FEB634",
    width: 50,
    padding: 3,
    textAlign: "center",
    fontSize: 9,

    borderRadius: 50,
  },
  score: {
    width: 90,
    fontSize: "14pt",
    fontFamily: "Montserrat-Bold",
  },
  footnote: {
    width: "100%",
    //height: 170,
    backgroundColor: "#F1F5F8",
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    marginBottom: 50,
  },
  link: {
    fontFamily: "Montserrat-Regular",
    textDecorationColor: "#979A9C",
    color: "#000",
    // opacity: 0.65,
    fontSize: 10,
    marginBottom: 2,
  },
});
