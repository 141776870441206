import React from "react";
import { motion } from "framer-motion";

const dots = [0, 1, 2, 3, 4];
const Loader = (props) => {
	return (
		<div style={{ display: "flex" }}>
			{dots.map((item, index) => {
				return <DOT key={index} i={index} />;
			})}
		</div>
	);
};

function DOT(props) {
	return (
		<motion.div
			style={{
				width: 20,
				height: 20,
				borderRadius: "50%",
				backgroundColor: "#710F14",
				margin: 12,
			}}
			animate={{
				scale: [1, 1.5, 1, 1.25, 1],
				//	width: [40, 20, 40, 20, 40],
				//	y: [0, 30, 0, 30, 0],
				//		rotate: [0, 0, 270, 270, 0],
				backgroundColor: [
					"#81171a",
					"#f78e23",
					"#ffc62e",
					"#008753",
					"#2876b6",
				],
				//	borderRadius: ["10%", "10%", "50%", "50%", "10%"],
			}}
			transition={{
				duration: 1,
				ease: "easeInOut",
				times: [0, 0.2, 0.5, 0.8, 1],
				loop: Infinity,
				delay: props.i / 4,
				repeatDelay: 0.7,
			}}
		/>
	);
}

export default Loader;
